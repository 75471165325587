import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Icon } from 'components';
import { CountScore } from 'feature';
import { useAppDispatch, useGetModalData, useGetUserData, useSettings } from 'hooks';
import { FriendInfo } from 'pages';
import { PATHS } from 'routes';
import { viewStaffInfoAction } from 'store/slices/settingsSlice';
import s from './styles.module.scss';

export const PageTitle = () => {
  const { pathname } = useLocation();
  const { viewStaffInfo } = useSettings();
  const { money, slots } = useGetUserData();
  const dispatch = useAppDispatch();
  const friendsInSlots = slots?.length && slots.some((_) => _.friend_id !== 0);

  const { hireEmployeeOfficeData } = useGetModalData();

  // Обработчик отображения информации о работнике (глаз)
  const handleOpenEye = () => {
    if (!viewStaffInfo) {
      dispatch(viewStaffInfoAction(true));
    } else {
      dispatch(viewStaffInfoAction(false));
    }
  };

  // Эффект скрытия информации о работнике если перешли на другую страницу
  useEffect(() => {
    if (pathname !== PATHS.OFFICE) {
      dispatch(viewStaffInfoAction(false));
    }
  }, [pathname]);

  // Тайтл страницы босса
  const bossPageTitle = () => {
    return (
      <div className={s.bossPageHeaderWrapper}>
        <CountScore count={money} />
        <NavLink to={PATHS.SETTINGS} className={s.settingsIcon}>
          <Icon iconName={'settingsIcon'} size={[45, 45]} />
        </NavLink>
      </div>
    );
  };

  // Тайтл страницы Офиса
  const officePageTitle = () => {
    return (
      <div className={s.bossPageHeaderWrapper}>
        <CountScore count={money} />
        {friendsInSlots && (
          <button onClick={handleOpenEye} className={s.eyeIcon}>
            {viewStaffInfo ? <Icon iconName={'eye_active'} /> : <Icon iconName={'showInfo'} />}
          </button>
        )}
      </div>
    );
  };

  // Объект отображения тайтлов страниц
  const pageTitles = {
    [PATHS.STAFF_OFFICE]: <FriendInfo data={hireEmployeeOfficeData} />,
    [PATHS.SETTINGS]: null,
    [PATHS.BOSS]: bossPageTitle(),
    [PATHS.OFFICE]: officePageTitle(),
    [PATHS.SHOP]: <CountScore count={money} />,
    [PATHS.FRIENDS]: <CountScore count={money} />,
  };

  return <>{pageTitles[pathname]}</>;
};
