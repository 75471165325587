import { useEffect } from 'react';
import { initData } from 'consts';
import { authenticate } from 'store/service';
import { generateRandomNumber } from 'shared/helpers/generateRandomNumber';

export const deviceId = generateRandomNumber();
// Хук запуска авторизации пользователя
export const useAuthorization = () => {
  let auth = false;

  const authenticateAndStartGame = async () => {
    try {
      console.log('auth');

      authenticate(initData!, deviceId);
      auth = true;
    } catch (error) {
      console.error(error);
    }
  };

  // Эффект для авторизации пользователя и запуска игры
  useEffect(() => {
    if (!auth) {
      if (deviceId) {
        authenticateAndStartGame();
      }
    }
  }, [deviceId]);
};
