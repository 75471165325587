import { useEffect, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalIdType } from 'components';
import { useAppDispatch, useGetUserData, useSettings, useTutor } from 'hooks';
import { PATHS } from 'routes';
import { TG_WEB_APP } from 'consts';
import { modalIsOpen } from 'store/slices/modalSlice';
import { increaseEnergyAndMoney } from 'store/slices/userSlice';
import { changeIsTutor, changeLanguage } from 'store/slices/settingsSlice';
import { LanguagesEnum } from 'shared/types';

export const useSideEffects = () => {
  const { pathname } = useLocation();
  const { isTutor } = useTutor();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    max_energy,
    energy,
    recovery_rate,
    user,
    revenue_rate,
    sell_friend_info,
    self_sell_profit,
    offline_revenue,
    invitedFriends,
  } = useGetUserData();
  const { lockVisited } = useSettings();

  // Выбор языка если он есть в локальном хранилище
  useEffect(() => {
    const language = localStorage.getItem('language') || TG_WEB_APP?.initDataUnsafe?.user?.language_code || 'en';
    if (language) {
      dispatch(changeLanguage(language as LanguagesEnum));
    }
  }, []);

  // Включение тутора
  useEffect(() => {
    if (user?.skill_levels?.['hr_guru'] === 1) {
      dispatch(changeIsTutor(true));
    }
  }, [user]);

  // Редиректы связки тутора и страницы лок и дальнейший переход на страницу оффиса
  useEffect(() => {
    if (isTutor) {
      navigate(PATHS.OFFICE);
      return;
    }
    if (!isTutor && (invitedFriends ?? []).length < 3 && !lockVisited) {
      navigate(PATHS.LOCK);
      return;
    }
  }, [isTutor, invitedFriends]);

  useEffect(() => {
    navigate(PATHS.OFFICE);
  }, []);

  // Запуск эффектов при рендере
  useEffect(() => {
    // Отключение скролла для страницы
    document.body.style.overflow = 'hidden';
    // Настройки Telegram WebApp
    if (TG_WEB_APP) {
      TG_WEB_APP.expand();
      TG_WEB_APP.disableVerticalSwipes();
    }
  }, []);

  // Эффект для увеличения энергии со временем
  useEffect(() => {
    const interval = setInterval(() => {
      if (energy && max_energy && recovery_rate && energy < max_energy) {
        dispatch(increaseEnergyAndMoney()); // Увеличиваем энергию
      }
    }, 1000);

    return () => clearInterval(interval); // Очищаем интервал при размонтировании
  }, [energy, max_energy, recovery_rate, dispatch, user, revenue_rate]);

  // Скролл на верх страницы при переходе на новую страницу
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Открытие модалки Welcome back по условиям
  useEffect(() => {
    if (invitedFriends?.length >= 3 && (sell_friend_info || self_sell_profit || offline_revenue > 10)) {
      dispatch(modalIsOpen(ModalIdType.WELCOME_BACK));
    }
  }, [user]);
};
