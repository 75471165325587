import { isProd, initData } from 'src/consts';
import { modalIsOpen } from 'store/slices/modalSlice';
import { ModalIdType } from 'src/components';
import { setErrorLogs } from 'store/slices/settingsSlice';
import store from '..';
import { handleServerResponse } from './handlers';

export let gameSocket: WebSocket;
let reconnectAllowed = true;

export function authenticate(initData: string, device_id: string) {
  const ws = isProd
    ? 'wss://office-game-prod.castcrown.com.cy/ws_auth'
    : 'wss://office-game-test.castcrown.com.cy/ws_auth';

  const socket = new WebSocket(ws);

  socket.onopen = function () {
    if (initData) {
      socket.send(initData);
    }
  };

  socket.onmessage = function (event) {
    const response = JSON.parse(event.data);
    console.log(JSON.parse(event.data));

    if (response.error) {
      console.error('Auth error:', response.error);
      setTimeout(authenticate, 2000); // Retry after 2 seconds
    } else if (response.token) {
      if (gameSocket?.readyState !== WebSocket.OPEN) {
        startGame(response.url, response.token, device_id);
      }
      socket.close();
    }
  };

  socket.onerror = function (error) {
    store.dispatch(setErrorLogs(`Auth WebSocket error: ${JSON.stringify(error)}, ${initData}`));
    setTimeout(authenticate, 2000); // Retry after 2 seconds
  };

  socket.onclose = function () {
    console.log('Auth websocket was closed');
    store.dispatch(setErrorLogs('Auth websocket was closed'));
  };
}

export function startGame(ws_game_addr: string, token: string, device_id: string) {
  reconnectAllowed = true;
  gameSocket = new WebSocket(ws_game_addr);

  gameSocket.onopen = function () {
    if (gameSocket && gameSocket.readyState === WebSocket.OPEN) {
      const initCmd = JSON.stringify({ cmd: 'init', token, device_id });
      gameSocket.send(initCmd);
    }
  };

  gameSocket.onmessage = function (event) {
    const data = JSON.parse(event.data);
    console.log(data);

    if (data.cmd === 'new_connection') {
      // Обработка события нового подключения
      showReconnectMessage();
      reconnectAllowed = false;
      gameSocket.close(); // Закрытие текущего соединения
    } else {
      handleServerResponse(data);
    }
  };

  gameSocket.onerror = function (error) {
    console.error('Game WebSocket error:', JSON.stringify(error));
    store.dispatch(setErrorLogs(`Game WebSocket error: ${JSON.stringify(error)} , ${initData}`));
  };

  gameSocket.onclose = function () {
    console.log('Game websocket was closed');
    store.dispatch(setErrorLogs('Game websocket was closed'));
    if (initData && reconnectAllowed) {
      authenticate(initData, device_id);
    }
  };
}

function showReconnectMessage() {
  // Отображение сообщения с кнопкой "ОК"
  store.dispatch(modalIsOpen(ModalIdType.NEW_CONNECTION));
}
