import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FriendCardLockPage, InviteFriend } from 'feature';
import { useGetUserData } from 'src/hooks';
import { isProd } from 'consts';
import s from './styles.module.scss';

export interface LockPageProps {}

const LockPage: FC<LockPageProps> = () => {
  const { t } = useTranslation();
  const { user_id, invitedFriends } = useGetUserData();

  return (
    <div className={s.wrapper}>
      <div className={s.contentWrapper}>
        <h2 className={s.title}>{t('lockPage.title')}</h2>

        <div className={s.cards}>
          <FriendCardLockPage user_id={user_id} classNames={s.first} friend={invitedFriends?.[0]} />
        </div>

        <div className={s.cards}>
          <FriendCardLockPage user_id={user_id} classNames={s.second} friend={invitedFriends?.[1]} />
          <FriendCardLockPage user_id={user_id} classNames={s.third} friend={invitedFriends?.[2]} />
        </div>

        <InviteFriend isContinueButton={isProd ? invitedFriends?.length >= 3 : true} />
      </div>
    </div>
  );
};

export default LockPage;
