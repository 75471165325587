import { useCallback, useEffect } from 'react';
import { Slot, StagesWorkEnum } from 'components';
import { OfficePlaces } from 'feature';
import { useGetUserData, useGetModalData } from 'src/hooks';
import { getFriendInfo } from 'store/service/methods';
import s from './styles.module.scss';

const StaffOfficePage = () => {
  const { hireEmployeeOfficeData } = useGetModalData();
  const { userFriendFriends, userFriendSlots } = useGetUserData();

  useEffect(() => {
    if (hireEmployeeOfficeData) {
      getFriendInfo(hireEmployeeOfficeData?.id);
    }
  }, [hireEmployeeOfficeData]);

  const drawTables = useCallback(
    (start, end, addIndex) => {
      return (
        <>
          {userFriendSlots?.slice(start, end)?.map((item, index) => (
            // @ts-ignore
            <Slot
              isWorking={StagesWorkEnum.NO_WORKING}
              key={index}
              slotIndex={index + addIndex}
              friend_id={index}
              friend_owner_id={item.friend_owner_id}
              isFriendsTables
              friendsFriend={userFriendFriends?.find((_) => _.id === item.friend_id)}
              slot={item}
            />
          ))}
        </>
      );
    },
    [userFriendFriends]
  );

  if (!hireEmployeeOfficeData) return;

  return (
    <div className={s.wrapper}>
      <OfficePlaces slotsLength={userFriendSlots?.length} drawTables={drawTables} />
    </div>
  );
};

export default StaffOfficePage;
