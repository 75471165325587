import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { UserCardsWrapper, TitleWrapper, Icon } from 'components';
import { useSettings } from 'hooks';
import { SettingTabs } from 'shared/types';
import s from '../styles.module.scss';

interface SettingsMainProps {
  onChange: (tab: SettingTabs) => void;
}
export const SettingsMain: FC<SettingsMainProps> = ({ onChange }) => {
  const { language } = useSettings();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // const handleDeleteAcc = () => {
  //   dispatch(modalIsOpen(ModalIdType.DELETE_ACC));
  // };

  return (
    <>
      <TitleWrapper>
        <div className={s.title}>{t('settings.title')}</div>
      </TitleWrapper>

      <div className={s.tabsWrapper}>
        <UserCardsWrapper>
          <div className={s.wrapperSettings} onClick={() => onChange(SettingTabs.CHANGE_LANGUAGE)}>
            <div className={s.tabWrapper}>
              <div className={s.deleteAccIcon}>
                <Icon iconName={'language'} />
              </div>

              <div>
                <h5>{t('settings.changeLanguage')}</h5>
                <h5 className={s.changed}>{t(`languages.${language}`)}</h5>
              </div>
            </div>

            <div className={s.tabIcon}>
              <Icon iconName={'settingsArrow'} />
            </div>
          </div>
        </UserCardsWrapper>
        <UserCardsWrapper
          onClick={() => {
            navigate(PATHS.LOGS);
          }}
        >
          Скачать логи
        </UserCardsWrapper>
        {/*<UserCardsWrapper>*/}
        {/*  <div className={s.wrapperSettings} onClick={handleDeleteAcc}>*/}
        {/*    <div className={s.tabWrapper}>*/}
        {/*      <div className={s.deleteAccIcon}>*/}
        {/*        <Icon iconName={'deleteAccount'} />*/}
        {/*      </div>*/}
        {/*      <h5>{t('settings.deleteAcc')}</h5>*/}
        {/*    </div>*/}

        {/*    <div className={s.tabIcon}>*/}
        {/*      <Icon iconName={'settingsArrow'} />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</UserCardsWrapper>*/}

        {/*<UserCardsWrapper>*/}
        {/*  <div className={s.wrapperSettings}>*/}
        {/*    <div className={s.tabWrapper}>*/}
        {/*      <div className={s.bellIcon}>*/}
        {/*        <Icon iconName={'bell'} />*/}
        {/*      </div>*/}
        {/*      <h5>{t('settings.notifications')}</h5>*/}
        {/*    </div>*/}

        {/*    <div className={s.tabIcon}>*/}
        {/*      <Icon iconName={'settingsArrow'} />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</UserCardsWrapper>*/}
      </div>
    </>
  );
};
