import { ModalIdType } from 'components';
import { TG_WEB_APP } from 'consts';
import store from 'store/index';
import { modalIsClose, modalIsOpen, setHireEmployeeModalData, setNewFriendInfo } from 'store/slices/modalSlice';
import {
  addNewFriend,
  freeEnergyBoost,
  handleNewRef,
  setFriends,
  setUserData,
  updateAfterBuyFriend,
  updateAfterClick,
  updateAfterGetFriendInfo,
  updateAfterGetWorkingRevenue,
  updateAfterManageSlot,
  updateAfterPurchase,
  updateAfterSellFriend,
  updateAfterStartWork,
  updateAfterUpgrade,
} from 'store/slices/userSlice';
import { setErrorLogs } from 'store/slices/settingsSlice';
import { sendGetFriends } from './methods';

// Создаем функцию для скачивания файла с ошибкой

export function handleServerResponse(data) {
  if (data.cmd !== 'init') {
    if (data.error) {
      store.dispatch(setErrorLogs(`___Error_${new Date().toISOString()} => ${JSON.stringify(data)}`));
    } else {
      store.dispatch(setErrorLogs(`___${new Date().toISOString()} => ${JSON.stringify(data)}`));
    }
  }
  if (data.error) {
    if (data.cmd === 'manage_slot' || data.cmd === 'start_work') {
      sendGetFriends();
    }
    return;
  }

  switch (data.cmd) {
    case 'init':
      handleInitResponse(data);
      break;
    case 'click':
      handleClickResponse(data);
      break;
    case 'buy_skill':
      handleBuySkillResponse(data);
      break;
    case 'get_friends':
      handleGetFriendsResponse(data);
      break;
    case 'get_friend_info':
      handleGetFriendInfoResponse(data);
      break;
    case 'buy_friend':
      handleBuyFriendResponse(data);
      break;
    case 'manage_slot':
      handleManageSlotResponse(data);
      break;
    case 'start_work':
      handleStartWorkResponse(data);
      break;
    case 'get_work_revenue':
      handleGetRevenueResponse(data);
      break;
    case 'purchase':
      TG_WEB_APP.openInvoice(data.link);
      break;
    case 'purchase_done':
      handlePurchaseDoneResponse(data);
      break;
    case 'sell_friend':
      handleSellFriendResponse(data);
      break;
    case 'new_friend':
      handleNewFriendInfoResponse(data);
      break;
    case 'free_energy_recovery':
      handleEnergyBoostResponse(data);
      break;
    case 'new_referral_id':
      handleNewRefResponse(data);
      break;
    default:
      console.error('Unknown command:', data.cmd);
  }
}

function handleInitResponse(response) {
  store.dispatch(setUserData(response));
}

function handleClickResponse(response) {
  store.dispatch(updateAfterClick(response));
}

function handleBuySkillResponse(response) {
  store.dispatch(updateAfterUpgrade(response));
}

function handleGetFriendsResponse(response) {
  store.dispatch(setFriends(response));
}

function handleBuyFriendResponse(response) {
  store.dispatch(updateAfterBuyFriend(response));
  store.dispatch(setHireEmployeeModalData(response));
}

function handleManageSlotResponse(response) {
  store.dispatch(updateAfterManageSlot(response));
  store.dispatch(modalIsClose());
}

function handleStartWorkResponse(response) {
  store.dispatch(updateAfterStartWork(response));
}

function handleGetRevenueResponse(response) {
  store.dispatch(updateAfterGetWorkingRevenue(response));
}

function handleGetFriendInfoResponse(response) {
  store.dispatch(updateAfterGetFriendInfo(response));
}

function handlePurchaseDoneResponse(response) {
  store.dispatch(updateAfterPurchase(response));
}

function handleSellFriendResponse(response) {
  store.dispatch(updateAfterSellFriend(response));
  store.dispatch(modalIsOpen(ModalIdType.SELL_FRIEND));
}

function handleNewFriendInfoResponse(response) {
  store.dispatch(setNewFriendInfo(response));
  store.dispatch(addNewFriend(response));
  store.dispatch(modalIsOpen(ModalIdType.NEW_FRIEND));
}

function handleEnergyBoostResponse(response) {
  store.dispatch(freeEnergyBoost(response));
}

function handleNewRefResponse(response) {
  store.dispatch(handleNewRef(response));
}
