import { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { inviteLink } from 'consts';
import { Avatar, Icon } from 'components';
import { Friend } from 'shared/types';
import s from './styles.module.scss';

export interface FriendCardLockPageProps {
  classNames?: string;
  friend: Friend;
  user_id: number;
}

export const FriendCardLockPage: FC<FriendCardLockPageProps> = ({ classNames, friend, user_id }) => {
  return (
    <>
      {friend ? (
        <div className={cn(s.wrapper, classNames)}>
          <div className={s.avatar}>
            <Avatar variant={'User'} image={friend?.photo_url} className={s.avatarComp} />
          </div>
          <span>
            {friend?.first_name ?? ''} {friend?.last_name ?? ''} {friend?.username ? `(${friend?.username})` : ''}
          </span>
        </div>
      ) : (
        <Link to={inviteLink(user_id).link}>
          <div className={cn(s.wrapper, classNames)}>
            <div className={s.avatar}>
              <Icon iconName="plus" size={[63, 63]} classNames={s.plus} />
            </div>
          </div>
        </Link>
      )}
    </>
  );
};
